#notFound {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  gap: 5px;
  text-align: center;
  padding: 0px 30px;

  .icon {
    font-size: 50px;
  }
  .text-notFound {
    display: flex;
    flex-direction: column;

    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 155%;
    text-align: center;

    text-align: center;
    letter-spacing: 1px;
    // color: #454647;

    small {
      font-weight: 400;
      font-size: 15px;
      line-height: 155%;
      text-align: center;

      text-align: center;
      letter-spacing: -0.25px;
      color: #454647;
    }
  }
}

@import "../../global-style//screen";
#layout {
  position: relative;
  background-color: #fff;
  font-family: "Work Sans", sans-serif;
  height: 100%;
  min-width: 290px;
  width: min(100%, 600px);
  min-height: 600px;
  @include extra-small-device() {
    height: 100vh;
  }
}

.p-auto {
  width: 100%;
  padding: 0px 20px;
}

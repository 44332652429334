@import "./global-style//text";
@import "./global-style//screen";
@import "./global-style//background_color";
@import "./global-style//mixin";

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");

.app {
  // background-color: #282c34;
  //   min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  // background-color: hotpink;
}

.btn {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &:focus {
    outline: none;
  }
  &:disabled {
    background: #c3cbd6;
    pointer-events: none;
  }
}

.skeleton {
  position: absolute;
  width: 100%;
  height: 10px;
  @include border-radius(8px);
  background-image: linear-gradient(
    65deg,
    var(--N50, #f3f4f5),
    var(--N100, #dbdee2)
  );
}

@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
}

@mixin box-shadow($x, $y, $blur, $radius, $color) {
	-webkit-box-shadow: $x $y $blur $radius $color;
	-moz-box-shadow: $x $y $blur $radius $color;
	-ms-box-shadow: $x $y $blur $radius $color;
	box-shadow: $x $y $blur $radius $color;
}

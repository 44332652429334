a {
	text-decoration: none !important;
	color: black !important;
	font-weight: 400;
}

h1 {
	font-size: 1.5rem;
	line-height: 2rem;
}

h2 {
	font-size: 1.25rem;
	line-height: 1.75rem;
}

h3 {
	font-size: 1.125rem;
	line-height: 1.65rem;
}

h4,
p {
	font-size: 1rem;
	line-height: 1.5rem;
}

h5 {
	font-size: 0.875rem;
	line-height: 1rem;
}

h1,
h2,
h3,
h4,
h5,
.font-bold,
strong {
	font-weight: 600;
}

p,
.font-normal,
label,
span {
	font-weight: 400;
}

.font-extra-bold {
	font-weight: 800;
}

.text-center {
	text-align: center;
}

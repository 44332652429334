#lazy-component {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  opacity: 0.8;

  .lazy-logo {
    animation: crescendo 1.5s alternate infinite ease-in;
  }

  @keyframes crescendo {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
  h4 {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 600;

    line-height: 100%;
    letter-spacing: 1px;
    color: #76787a;
  }
}

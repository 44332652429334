.bg-disable {
	background-color: #c3cbd6;
}

.bg-active {
	background-color: #08b293;
}

.bg-white {
	background-color: white;
}
